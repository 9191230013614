<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Müşteri Grubu</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Müşteri Grubu Ekle</span>
      </h3>
      <v-col class="text-right p-0">
        <!-- <rs-action
          @click="showActionLogs"
          v-if="id"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action> -->
        <rs-action-delete
          target="_blank"
          confirmed
          @click="handleDeleteClick"
          v-if="id"
          icon
          tooltip="Sil"
          :is-loading="isLoading"
        />
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Genel</v-tab>
        <v-tab to="#customers" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.customer_count || '0'"
          >
            Müşteriler
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form
            :readonly="disabled"
            @submit.prevent.stop="handleFormSubmit"
            ref="form"
            :disabled="isLoading || disabled"
          >
            <v-row dense>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 150)]"
                  v-model="formData.name"
                  hide-details="auto"
                />
              </v-col>
            </v-row>

            <rs-form-buttons
              :isLoading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="customers" class="pa-4">
          <CustomerList :customer-group-id="id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm } from "@/view/mixins";
import { CustomerList } from "../components";

export default {
  mixins: [hasForm],
  components: { CustomerList },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        name: null,
      },
      totals: {},
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/customer-groups/${this.id}`)
        .then((response) => {
          this.loadData(response);

          this.totals = response.data.data.totals || {};
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`api-super-admin/customer-groups/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`api-super-admin/customer-groups`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "admin.crm.customer-groups.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      return this.$api
        .delete(`api-super-admin/customer-groups/${this.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));

          this.$router.replace({
            name: "admin.crm.customer-groups.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.$router.back();
    },
    // showActionLogs() {
    //   this.$refs.actionLogList.show("App\\Models\\Resident", this.id);
    // },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}

.identity-number input {
  letter-spacing: 0.3vw;
}
</style>
